import { Header } from './modules/header';
import { Footer } from './modules/footer';
import { Home } from './modules/pages/home';
import { ProjectArchive } from './modules/pages/project_archive';
import { About } from './modules/pages/about';
import { Project } from './modules/pages/project';
import { Careers } from './modules/pages/careers';
import { Contact } from './modules/pages/contact';
import { CustomerCare } from './modules/pages/customer_care';
import { ThankYou } from './modules/pages/thank_you';
import smoothscroll from 'smoothscroll-polyfill';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { Parallax } from './modules/parallax';
import { SecondaryRegistration } from './modules/pages/secondary_registration';

class App {
    constructor(page, initialized) {
        this.page = page;
        this.initialized = initialized;
        gsap.registerPlugin(ScrollTrigger);

        if (this.initialized == false) {
            if (document.readyState === 'loading') {
                document.addEventListener('DOMContentLoaded', this.init());
            } else {
                this.init();
            }
        }
    }

    init() {
        this.initialized = true;
        smoothscroll.polyfill();
        new Header(document.getElementById('header'), false).init();
        new Footer().init();
        new Parallax(document.querySelectorAll('[data-parallax]'));

        switch (true) {
            case this.page.classList.contains('home'):
                new Home();
                break;

            case this.page.classList.contains(
                'page-template-template-project-archive'
            ):
                new ProjectArchive();
                break;

            case this.page.classList.contains('page-template-template-about'):
                new About();
                break;

            case this.page.classList.contains('single-projects'):
                new Project();
                break;

            case this.page.classList.contains('page-template-template-careers'):
                new Careers();
                break;

            case this.page.classList.contains('page-template-template-contact'):
                new Contact();
                break;

            case this.page.classList.contains(
                'page-template-template-customer-care'
            ):
                new CustomerCare();
                break;

            case this.page.classList.contains(
                'page-template-template-thank-you'
            ):
                new ThankYou();
                break;

            case this.page.classList.contains(
                'page-template-template-secondary-registration'
            ):
                console.log('test');
                new SecondaryRegistration().init();
                break;
        }
    }
}

new App(document.querySelector('body'), false);
