class Subnav {

    constructor(options, yOffset) {
        this.triggers = document.querySelectorAll('[data-subnav="trigger"]');
        this.subnav = document.querySelector('[data-subnav="menu_container"]');
        this.menuItems = document.querySelectorAll('[data-subnav="menu-item"]');
        this.colorChange = document.querySelectorAll('[data-subnav="color_change"]');
        this.colorTriggerPosition = (parseInt(this.subnav.getBoundingClientRect().height) / 2) + parseInt(window.getComputedStyle(this.subnav).top);
        this.options = options;
        this.yOffset = yOffset;
        this.init();
    };


    init() {
        this.triggers.forEach((trigger, i) => {
            this.registerObserver(trigger, this.options);
        });

        this.menuItems.forEach(item => {
            this.registerClickEvent(item, this.yOffset);
        });
    };

    registerObserver = (element, options) => {
        var colorChangeTrigger = element.children.length ? element.children[0] : false;
        var observer = new window.IntersectionObserver(this.observerHandler, options);

        if (colorChangeTrigger) {
            colorChangeTrigger.style.margin = `-${this.colorTriggerPosition}px 0px 0px 0px`;
            observer.observe(colorChangeTrigger);            
        };

        observer.observe(element);
    };

    observerHandler = (entries) => {
        entries.forEach((entry, i) => {
            if (entry.isIntersecting) {
                this.setCurrent(entry.target);
            };
        });
    };

    setCurrent(entry, dataType) {
        var dataType = entry.getAttribute('data-subnav');
        var index = entry.dataset.snindex;
        var currMenuItem = Array.from(this.menuItems).find((node) => node.dataset.snindex === index);
        var colorChange = entry.children.length ? entry.children[0].dataset.subnav == 'color_change' : false;

        this.menuItems.forEach(item => {
            item.classList.remove('subnav__current');
            item.setAttribute('data-sncolor', '0');
        });

        if (dataType === 'trigger') {
            currMenuItem.classList.add('subnav__current');
            currMenuItem.setAttribute('data-sncolor', '0');
            if (!colorChange) {
                this.subnav.setAttribute('data-sncolor', '0');
            }
        }

        if (dataType === 'color_change') {
            this.subnav.setAttribute('data-sncolor', '1');
        }
    }

    registerClickEvent(element, yOffset) {
        element.addEventListener('click', function (e) {
            e.preventDefault();
            var index = e.target.dataset.snindex;
            var element = Array.from(this.triggers).find((trigger) => trigger.dataset.snindex === index);
            var y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({top: y, behavior:'smooth'});
        }.bind(this));
    }
}

export {
    Subnav
};