import { Accordion } from '../accordion';
import { Subnav } from '../subnav';

class CustomerCare {
	constructor() {
		this.init();
	}

	init() {
		new Accordion(document.querySelector('#accordion.vertical'));
		new Subnav(
			{
				rootMargin: '0px 0px -50% 0px',
			},
			-100
		);

		AOS.init({
			offset: 50,
			disable: 'mobile',
		});
	}
}

export { CustomerCare };
