import { Slick } from '../slick';
import { Hamburger } from '../hamburger';
import { Accordion } from '../accordion';

class Home {
	constructor() {
		this.init();
	}

	init() {
		new Slick("[data-slider='hero']", {
			adaptiveHeight: true,
			slidesToShow: 1,
			slidesToScroll: 1,
			infinite: true,
			variableWidth: true,
			speed: 0,
			autoplay: true,
			autoplaySpeed: 4000,
			dots: true,
			prevArrow: false,
			nextArrow: document.querySelector('.next'),
		});

		new Slick("[data-slider='testimonials']", {
			adaptiveHeight: true,
			slidesToShow: 1,
			slidesToScroll: 1,
			infinite: true,
			dots: true,
			prevArrow: document.querySelector('.testimonials__arrows .prev'),
			nextArrow: document.querySelector('.testimonials__arrows .next'),
		});

		new Hamburger(
			document.querySelector('.hero-slider__menu'),
			document.querySelector('#menu-communities'),
			document.querySelector('.hero-slider__nav--container')
		).init();

		new Accordion(document.querySelector('#accordion.horizontal'));

		AOS.init({
			useClassNames: true,
			offset: 100,
			disable: 'mobile',
		});
	}
}

export { Home };
