import { ProjectForm } from '../modules/project_form';

class Footer {
    constructor() {
        this.trigger = document.querySelector('[data-trigger="backToTop"]');
        this.recaptcha = document.querySelector('#footer #recaptcha');
    }

    init() {
        this.registerEvents();

        if (this.recaptcha) {
            new ProjectForm(this.recaptcha.value, 'footer');
        }
    }

    registerEvents() {
        this.trigger.addEventListener('click', () => {
            this.backToTop();
        });
    }

    backToTop() {
        document.body.scrollTop = document.documentElement.scrollTop = 0;
    }
}

export { Footer };
