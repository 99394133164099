import { Slick } from '../slick';

class ThankYou {
	constructor() {
		this.init();
	}

	init() {
		new Slick("[data-slider='project-archive']", {
			mobileFirst: true,
			adaptiveHeight: true,
			variableWidth: true,
			prevArrow: false,
			nextArrow: false,
			infinite: true,
			dots: true,
			centerMode: true,
			centerPadding: '0 60px',
			slidesToShow: 1,
			slidesToScroll: 1,
			responsive: [
				{
					breakpoint: 900,
					settings: 'unslick',
				},
			],
		});
		AOS.init({
			useClassNames: true,
			offset: 100,
			disable: 'mobile',
		});
	}
}

export { ThankYou };
