import { isTouchDevice } from './helpers';

class Slick {
    constructor(element, options) {
        this.element = element;
        this.options = options;
        this.init();
    }

    init() {
        this.slickCreate(this.element, this.options);
        this.slickResize(this.element, this.options);
        this.slickVideo(this.element);
    }

    slickCreate(element, options) {
        jQuery(element).not('.slick-initialized').slick(options);
    }

    slickResize(element, options) {
        if (!isTouchDevice()) {
            window.addEventListener(
                'resize',
                function (e) {
                    const el = jQuery(element);

                    if (
                        options.mobileFirst &&
                        !el.hasClass('.slick-initialized')
                    ) {
                        el.slick(options);
                    }

                    if (el.hasClass('slick-initialized')) {
                        setTimeout(function () {
                            el.slick('unslick');
                        }, 250);

                        el.addClass('refreshing');

                        setTimeout(function () {
                            el.slick(options);

                            if (el.hasClass('refreshing')) {
                                el.removeClass('refreshing');
                            }

                            AOS.init({
                                offset: 50,
                                disable: 'mobile'
                            });
                        }, 250);
                    }
                }.bind(this)
            );
        }
    }

    slickVideo(element) {
        // On slide change, pause all videos
        jQuery(element).on(
            'beforeChange',
            function (event, slick, currentSlide, nextSlide) {
                jQuery('.slick-slide video').each(function () {
                    jQuery(this).get(0).pause();
                });
            }
        );

        // On slide chnage, play a video inside the current slide
        jQuery(element).on(
            'afterChange',
            function (event, slick, currentSlide, nextSlide) {
                if (jQuery('.slick-current').find('video').length !== 0) {
                    jQuery('.slick-current video')[0].play();
                }
            }
        );
    }
}

export { Slick };
