export default class Accordion {
    constructor(component) {
        this.component = component;
        this.trigger = this.component.querySelectorAll(
            '[data-accordion="trigger"]'
        );
        this.triggerHeight = 80;
        this.init();
    }

    init() {
        if (this.component.classList.contains('vertical')) {
            this.trigger[0].classList.remove('closed');
            this.trigger[0].classList.add('open');
        } else {
            this.trigger.forEach((trigger) => {
                this.registerHoverEvent(trigger);
            });
        }

        this.trigger.forEach((trigger) => {
            this.registerClickEvent(trigger);
        });
    }

    registerHoverEvent(element) {
        element.addEventListener(
            'mouseenter',
            function (e) {
                this.trigger.forEach((trigger) => {
                    trigger.classList.add('hover');
                    e.target.classList.remove('hover');
                });
            }.bind(this)
        );
        element.addEventListener(
            'mouseleave',
            function (e) {
                this.trigger.forEach((trigger) => {
                    trigger.classList.remove('hover');
                });
            }.bind(this)
        );
    }

    registerClickEvent(element) {
        element.addEventListener(
            'click',
            function (e) {
                this.trigger.forEach((trigger) => {
                    if (trigger != element) {
                        trigger.classList.remove('open');
                        e.stopPropagation();
                    }
                });

                if (this.component.classList.contains('vertical')) {
                    const yOffset = -120;
                    const y =
                        element.getBoundingClientRect().top +
                        window.pageYOffset +
                        yOffset;

                    window.scrollTo({ top: y, behavior: 'smooth' });
                }

                setTimeout(() => {
                    if (e.target.classList.contains('open')) {
                        e.target.classList.remove('open');
                        e.target.classList.add('closed');
                        e.stopPropagation();
                    } else {
                        e.target.classList.remove('closed');
                        e.target.classList.add('open');
                        e.stopPropagation();
                    }
                }, 250);
            }.bind(this)
        );
    }
}

export { Accordion };
