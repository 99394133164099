import { CommunityFilter } from '../community_filter';
import { Slick } from '../slick';

class ProjectArchive {
	constructor() {
		this.init();
	}

	init() {
		new Slick("[data-slider='project-archive']", {
			mobileFirst: true,
			adaptiveHeight: true,
			variableWidth: true,
			prevArrow: false,
			nextArrow: false,
			infinite: true,
			dots: true,
			centerMode: true,
			centerPadding: '0 60px',
			slidesToShow: 1,
			slidesToScroll: 1,
			responsive: [
				{
					breakpoint: 900,
					settings: 'unslick',
				},
			],
		});

		if (window.location.hash) {
			new CommunityFilter(window.location.hash);
		} else {
			new CommunityFilter();
		}

		window.onload = () => {
			AOS.init({
				useClassNames: true,
				offset: 100,
				disable: 'mobile',
			});
		};
	}
}

export { ProjectArchive };
