import { Accordion } from '../accordion';
import { Subnav } from '../subnav';

var members = [];

function showMember(that) {
    const designation = jQuery(that).data('designation');
    const name = designation
        ? jQuery(that).data('name') + ','
        : jQuery(that).data('name');
    const title = jQuery(that).data('title');
    const linkedin = jQuery(that).data('linkedin')
        ? `
        <a href="${jQuery(that).data('linkedin')}">
        <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0 0V28H28V0H0ZM6.8667 5.50464C7.97573 5.51712 9.0682 6.28222 9.11573 7.60669C9.13943 8.78444 8.11307 9.68151 6.83765 9.70874H6.80689C5.7089 9.69607 4.63661 8.91177 4.58691 7.60669C4.60257 6.44205 5.58154 5.53226 6.8667 5.50464ZM18.7646 11.0691C19.9839 11.0767 21.1347 11.4384 22.0801 12.4021C23.0625 13.4979 23.378 15.0087 23.4131 16.5447V23.531H19.3867V17.0198C19.3778 15.7994 18.9597 14.316 17.3445 14.2666C16.3978 14.2767 15.7303 14.8268 15.2732 15.7466C15.1484 16.0426 15.1304 16.383 15.1245 16.7224V23.531H11.0999C11.1153 20.1272 11.1364 16.7237 11.1289 13.3198C11.1289 12.3529 11.1196 11.7019 11.0999 11.3665H15.1245V13.0823C15.4661 12.5964 15.845 12.1356 16.3532 11.7646C17.0426 11.2727 17.8707 11.0841 18.7646 11.0691ZM4.82447 11.3665H8.84912V23.531H4.82447V11.3665Z" fill="#444444"></path>
        </svg>
    </a>`
        : '';
    const bio = jQuery(that).data('bio');
    const imageA = jQuery(that).data('imagea');
    const imageB = jQuery(that).data('imageb');
    const projects = jQuery(that).data('projects');
    const departmentString = jQuery(that).data('department');
    const department =
        departmentString.split('|').length > 0
            ? departmentString.split('|')[0]
            : departmentString;

    // Update pop-up window data
    jQuery('.department-name').text(department);
    jQuery('.popup__right--title>h1').text(name);
    jQuery('.popup__right--title>span').text(designation);
    jQuery('.popup__right--subtitle>h2').text(title);
    jQuery('.popup__right--bio').html(bio);
    jQuery('.popup__right--linkedin').html(linkedin);
    jQuery('.popup__right--projects').html('<h6>Project Experience</h6>'); // Clear old data and add title

    jQuery('.popup__left--images').html('');
    if (imageA !== '') {
        jQuery('.popup__left--images').html(
            `<img class="image_a" src="${imageA}" alt="${name}" />`
        );
    }

    if (imageB !== '') {
        jQuery('.popup__left--images').append(
            `<img class="image_b" src="${imageB}" alt=${name} />`
        );
    }

    jQuery('.popup__right--projects').append(projects);
    jQuery('.popup__left--subnav').html(
        `<p><strong class="member__title">${name}</strong></p>`
    ); // Clear old data and add person's name first place
    members.forEach((member) => {
        if (
            name !== member.name &&
            department !== '' &&
            member.department.includes(department)
        )
            jQuery('.popup__left--subnav').append(
                `<p class="department-list" data-popup="${member.popup}" data-name="${member.name}" data-department="${member.department}" >${member.name}</p>`
            );
    });

    jQuery('#popup').addClass('popup__open');

    // Register click event
    jQuery('.department-list[data-popup="1"]').on('click', function () {
        const targetName = jQuery(this).data('name');
        const targetObject = jQuery(
            `.team--container[data-name='${targetName}'`
        );
        showMember(targetObject);
    });
}
class About {
    constructor() {
        this.inputs = document.querySelectorAll(
            'input.department-filter-input'
        );

        this.select = document.querySelector('select.department-filter-mobile');
        this.loadMore = document.querySelector('[data-load-more="switch"]');
        this.hash = window.location.hash;
        this.init();
    }

    init() {
        window.onload = () => {
            AOS.init({
                useClassNames: true,
                offset: 100,
                disable: 'mobile'
            });

            jQuery('.team--container[data-department]').each(function () {
                members.push({
                    name: jQuery(this).data('name'),
                    department: jQuery(this).data('department'),
                    popup: jQuery(this).data('popup')
                });

                members.sort((a, b) => {
                    let fa = a.name.toLowerCase(),
                        fb = b.name.toLowerCase();

                    if (fa < fb) {
                        return -1;
                    }
                    if (fa > fb) {
                        return 1;
                    }
                    return 0;
                });
            });

            // new PopUp();
            jQuery('.popup__right--close').on('click', function () {
                jQuery('#popup').removeClass('popup__open');
            });
            jQuery('.team--container[data-popup=1]').on('click', function () {
                showMember(jQuery(this));
            });
            this.registerEvents([this.inputs, this.select, this.loadMore]);
        };

        new Accordion(document.querySelector('#accordion.horizontal'));
        new Accordion(document.querySelector('#accordion.vertical'));
        new Subnav(
            {
                rootMargin: '0px 0px -100px 0px'
            },
            10
        );
    }

    registerEvents(events) {
        events[0].forEach((input) =>
            input.addEventListener('change', function () {
                const filterData = this.value;
                jQuery('.team--container[data-department]').each(function () {
                    if (jQuery(this).data('department').includes(filterData)) {
                        jQuery(this).show();
                    } else {
                        jQuery(this).hide();
                    }
                });

                jQuery('.button').removeClass('active');
                jQuery(this).next().addClass('active');
            })
        );
        events[1].addEventListener('change', function () {
            const filterData = this.value;
            jQuery('.team--container[data-department]').each(function () {
                if (jQuery(this).data('department').includes(filterData)) {
                    jQuery(this).show();
                } else {
                    jQuery(this).hide();
                }
            });
        });
        events[2].addEventListener('click', function (e) {
            e.preventDefault();
            e.target.parentElement.classList.add('loaded');
            document
                .querySelectorAll('[data-load-more="1"]')
                .forEach((item) => item.classList.add('show'));
        });
    }
}

export { About };
