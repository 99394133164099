class ProjectForm {
    constructor(recaptchaKey, locale) {
        switch (locale) {
            case 'footer':
                this.form = document.querySelector(
                    '[data-form="project_form_footer"]'
                );
                break;

            case 'header':
                this.form = document.querySelector(
                    '[data-form="project_form_header"]'
                );

                break;

            case 'secondary':
                this.form = document.querySelector(
                    '[data-form="project_form_secondary"]'
                );

                break;

            default:
                this.form = document.querySelector(
                    '[data-form="project_form"]'
                );
        }

        if (this.form) {
            this.locale = locale;

            this.textFields = [
                this.form.querySelector('input[name="contact[first_name]"]'),
                this.form.querySelector('input[name="contact[last_name]"]'),
                this.form.querySelector('input[name="contact[city]"]')
            ];

            this.submit = this.form.querySelectorAll(
                '[data-form="project_submit"]'
            );
            this.recaptchaKey = recaptchaKey;

            this.init();
        }
    }

    init() {
        document.getElementById('are_you_simulated').style.display = 'none';
        this.registerEvents();
    }

    registerEvents = () => {
        // Capitalize First Letter
        this.textFields.forEach((input) => {
            if (input) {
                input.addEventListener('keyup', function (e) {
                    var txt = e.target.value;
                    // Regex taken from php.js (http://phpjs.org/functions/ucwords:569)
                    var newTxt = txt.replace(/^(.)|\s(.)/g, function ($1) {
                        return $1.toUpperCase();
                    });
                    e.target.value = newTxt;
                });
                input.addEventListener('blur', function (e) {
                    var txt = e.target.value;
                    // Regex taken from php.js (http://phpjs.org/functions/ucwords:569)
                    var newTxt = txt.replace(/^(.)|\s(.)/g, function ($1) {
                        return $1.toUpperCase();
                    });
                    e.target.value = newTxt;
                });
            }
        });

        // Submit Events
        this.submit.forEach((button) => {
            button.addEventListener('click', () => {
                this.submitRegistrationForm(this.submit);
            });
        });
    };

    onlyOne(checkbox) {
        var checkboxes = document.getElementsByName('agent');
        checkboxes.forEach((item) => {
            if (item !== checkbox) item.checked = false;
        });
    }

    // Rebuilt using Spark's inbedded form handler as a base
    submitRegistrationForm = () => {
        var form = this.form;
        var missing = '';
        var required = {
            contact_email: 'Email'
        };
        var customRequired = form.querySelectorAll(
            'input:required, textarea:required, select:required'
        );

        var submitting = false;

        // Auto fill hidden email field for seconday form
        if (this.locale === 'secondary') {
            const email = sessionStorage.getItem('email');

            form.querySelector('#contact_email').value = email;
        }

        // Adds custom required inputs to the 'required' object
        for (var i = 0; i < customRequired.length; i++) {
            required[customRequired[i].id] = customRequired[
                i
            ].parentNode.firstElementChild.innerHTML.replace('*', '');
        }

        // Iterates through required fields and adds any that have
        // not been populated to 'missing' list
        for (var key in required) {
            var elements = Array.from(
                form.querySelectorAll("[id^='" + key + "']")
            );

            if (elements.length > 0) {
                var missing_field = true;

                elements.forEach(function (el) {
                    if (
                        el.length < 1 ||
                        (el &&
                            ((el.type == 'checkbox' && el.checked) ||
                                (el.type == 'radio' && el.checked) ||
                                (el.type != 'radio' &&
                                    el.type != 'checkbox' &&
                                    el.value) ||
                                (document.getElementById(key + '_other_text') &&
                                    document.getElementById(key + '_other_text')
                                        .value)))
                    ) {
                        missing_field = false;
                    }
                });

                if (missing_field) {
                    missing += '-' + required[key] + '\r\n';
                }
            }
        }

        // Tests email input value against RFC 5322 Official Standard Email Regex
        var email = form.querySelector('#contact_email').value;

        // Used for secondary form
        if (this.locale === 'contact' || this.locale === 'footer') {
            sessionStorage.setItem('email', email);
        }

        if (
            !/(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/.test(
                email
            )
        ) {
            missing += '- Email is invalid\r\n';
        }

        if (missing != '') {
            alert('The following fields are incomplete:\r\n' + missing);
            return false;
        }

        // Prevents duplicate submissions
        if (submitting) {
            return false;
        }
        submitting = true;

        var siteKey = this.recaptchaKey;
        grecaptcha.ready(function () {
            grecaptcha
                .execute(siteKey, {
                    action: 'registration'
                })
                .then(function (token) {
                    const tokenInput = document.querySelector(
                        '.g-recaptcha-response'
                    );
                    if (tokenInput) {
                        tokenInput.value = token;
                        form.appendChild(tokenInput);
                    }
                    form.submit();
                });
        });
    };
}

export { ProjectForm };
