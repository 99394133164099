import { Slick } from '../slick';
import { ProjectForm } from '../project_form';
import { PopUp } from '../popUp';
import { Timeline } from '../timeline';
import { Slider } from '../slider';
import { VideoBlock } from '../video_block';
import { Accordion } from '../accordion';

class Project {
    constructor() {
        this.main = document.querySelector('#main');
        this.recaptcha = document.querySelector('#recaptcha');
        this.form = document.querySelector('#project-form');

        this.init();
    }

    init() {
        new Slick("[data-slider='hero']", {
            adaptiveHeight: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: true,
            variableWidth: false,
            // autoplay: true,
            autoplaySpeed: 4000,
            speed: 0,
            dots: true,
            prevArrow: false,
            nextArrow: document.querySelector('.next')
        });

        new Slider();

        switch (this.main.classList.value) {
            case 'project-sold-out':
                this.soldOutTemplate();
                break;

            case 'project-default':
                this.defaultTemplate();
                break;

            case 'project-case-study':
                this.caseStudyTemplate();
                break;

            case 'project-limited':
                this.limitedTemplate();
                break;

            case 'project-commercial':
                this.commercialTemplate();
                break;

            default:
                this.defaultTemplate();
                break;
        }

        AOS.init({
            offset: 50,
            disable: 'mobile'
        });
    }

    defaultTemplate() {
        new Timeline();
        new PopUp();
        if (this.recaptcha && this.form) {
            new ProjectForm(this.recaptcha.value);
        }
    }

    limitedTemplate() {
        new PopUp();

        new Slick("[data-slider='gallery']", {
            centerMode: true,
            infinite: true,
            centerPadding: '250px',
            initialSlide: 0,
            slidesToShow: 1,
            speed: 0,
            dots: false,
            prevArrow: document.querySelector('.project-gallery__arrows .prev'),
            nextArrow: document.querySelector('.project-gallery__arrows .next'),
            responsive: [
                {
                    breakpoint: 901,
                    settings: {
                        adaptiveHeight: true,
                        infinite: true,
                        dots: true,
                        centerPadding: '0 60px',
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]
        });
        if (this.recaptcha && this.form) {
            new ProjectForm(this.recaptcha.value);
        }
    }

    soldOutTemplate() {
        new Slick("[data-slider='testimonials']", {
            adaptiveHeight: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: true,
            dots: true,
            prevArrow: document.querySelector('.testimonials__arrows .prev'),
            nextArrow: document.querySelector('.testimonials__arrows .next')
        });

        new PopUp();

        new Slick("[data-slider='gallery']", {
            centerMode: true,
            infinite: false,
            centerPadding: '250px',
            initialSlide: 0,
            slidesToShow: 1,
            speed: 1000,
            dots: false,
            prevArrow: document.querySelector('.project-gallery__arrows .prev'),
            nextArrow: document.querySelector('.project-gallery__arrows .next'),
            responsive: [
                {
                    breakpoint: 901,
                    settings: {
                        adaptiveHeight: true,
                        infinite: true,
                        dots: true,
                        centerPadding: '0 60px',
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]
        });
    }

    caseStudyTemplate() {
        new VideoBlock().init();
        new Accordion(document.querySelector('#case_study-fifty_list'));

        document.querySelectorAll('.next').forEach((el) => {
            el.addEventListener('click', function (e) {
                console.log(e.target);
            });
        });

        new Slick("[data-slider='testimonials']", {
            adaptiveHeight: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: true,
            dots: true,
            prevArrow: document.querySelector('.testimonials__arrows .prev'),
            nextArrow: document.querySelector('.testimonials__arrows .next')
        });

        new Slick("[data-slider='gallery']", {
            infinite: true,
            centerPadding: '250px',
            initialSlide: 0,
            slidesToShow: 1,
            speed: 1000,
            dots: false,
            prevArrow: document.querySelector(
                '.case_study-fifty_slider__arrows .prev'
            ),
            nextArrow: document.querySelector(
                '.case_study-fifty_slider__arrows .next'
            ),
            responsive: [
                {
                    breakpoint: 1001,
                    settings: {
                        adaptiveHeight: true,
                        infinite: true,
                        dots: true,
                        centerPadding: '0 60px',
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        prevArrow: false,
                        nextArrow: false
                    }
                }
            ]
        });

        new Slick("[data-slider='events']", {
            infinite: false,
            centerPadding: '250px',
            initialSlide: 0,
            slidesToShow: 1,
            arrows: false,
            speed: 1000,
            dots: false,
            adaptiveHeight: true,
            infinite: true,
            dots: true,
            centerPadding: '0 60px',
            slidesToShow: 1,
            slidesToScroll: 1,
            mobileFirst: true,
            responsive: [
                {
                    breakpoint: 769,
                    settings: 'unslick'
                }
            ]
        });
    }

    commercialTemplate() {
        new PopUp();

        new Slick("[data-slider='gallery']", {
            centerMode: true,
            infinite: false,
            centerPadding: '250px',
            initialSlide: 0,
            slidesToShow: 1,
            speed: 1000,
            dots: false,
            prevArrow: document.querySelector('.project-gallery__arrows .prev'),
            nextArrow: document.querySelector('.project-gallery__arrows .next'),
            responsive: [
                {
                    breakpoint: 901,
                    settings: {
                        adaptiveHeight: true,
                        infinite: true,
                        dots: true,
                        centerPadding: '0 60px',
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]
        });
    }
}

export { Project };
