class VideoBlock {
    constructor() {
        this.components = document.querySelectorAll('[data-video="trigger"]');
    }

    init() {
        this.registerEvents();
    }

    registerEvents() {
        this.components.forEach((component) => {
            const overlay = component.nextElementSibling;
            component.addEventListener(
                'click',
                function () {
                    if (overlay.classList.contains('playing')) {
                        overlay.classList.remove('playing');
                        component.pause();
                    } else {
                        overlay.classList.add('playing');
                        component.play();
                    }
                }.bind(this)
            );
        });
    }
}

export { VideoBlock };
