class Hamburger {
	constructor(button, menu, container, fixed) {
		this.button = button;
		this.menu = menu;
		this.container = container;
		this.fixed = fixed;
	}

	init() {
		this.clickEvent();
	}

	clickEvent() {
		let menuBtn = this.button;
		let menu = this.menu;
		let header = this.container;
		let fix = this.fixed;

		menuBtn.addEventListener('click', function (e) {
			if (!e.target.classList.contains('communities')) {
				if (menu) {
					menu.classList.toggle('menu-open');
				}
				if (menuBtn) {
					menuBtn.classList.toggle('menu-open');
				}
				if (header) {
					header.classList.toggle('menu-open');
				}
			}

			if (fix) {
				document.querySelector('body').classList.toggle('fixed');
			}
		});
	}
}

export { Hamburger };
