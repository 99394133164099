import { Accordion } from '../accordion';

class Careers {
    constructor() {
        this.init();
    }

    init() {
        new Accordion(document.querySelector('#accordion.horizontal'));

        AOS.init({
            offset: 50,
            disable: 'mobile'
        });

        jQuery('.position__header').on('click', function () {
            if (jQuery(this).parent().hasClass('position--active')) {
                jQuery(this).parent().removeClass('position--active');
            } else {
                jQuery('.position').removeClass('position--active');
                jQuery(this).parent().addClass('position--active');
            }
        });
    }
}

export { Careers };
