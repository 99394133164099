import { Parallax } from './parallax';

class CommunityFilter {
    constructor(hash) {
        this.init(hash);
    }

    init(hash) {
        this.registerClickEvents();

        // Add Select2 to filter for styling
        jQuery('.community-filter').select2({
            width: 'resolve',
            minimumResultsForSearch: Infinity
        });

        // If linked from homepage take account of that hash
        if (hash && hash == '#communities-selection') {
            this.scrollToAnchor();
            this.ajaxPostFunction();
        } else if (hash && hash != '#communities-selection') {
            this.hash = hash.slice(1);
            this.hashValue = parseInt(
                document.querySelector(`[name='${this.hash}']`).value
            );
            this.scrollToAnchor();
            this.ajaxPostFunction(false, this.hashValue)
                .then(() => {
                    new Parallax(true, true);
                    AOS.refresh();
                })
                .catch((error) => {
                    console.log(error);
                });
        } else {
            this.ajaxPostFunction()
                .then(() => {
                    new Parallax(true, true);
                    AOS.refresh();
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    }

    registerClickEvents() {
        jQuery('.community-filter').on(
            'change',
            function (e) {
                this.ajaxPostFunction(e.target);
                this.scrollToAnchor();
            }.bind(this)
        );

        // Test
        document.querySelectorAll('label.type-filter').forEach((input) => {
            input.addEventListener(
                'click',
                function (e) {
                    document
                        .querySelectorAll('label.type-filter')
                        .forEach((input) => {
                            input.classList.remove('checked');
                        });
                    e.target.classList.add('checked');
                    e.target.nextElementSibling.checked = true;
                    this.ajaxPostFunction(e.target.nextElementSibling)
                        .then(() => {
                            new Parallax(true, true);
                            AOS.refresh();
                        })
                        .catch((error) => {
                            console.log(error);
                        });
                    this.scrollToAnchor();
                }.bind(this)
            );
        });
    }

    scrollToAnchor() {
        var loc = jQuery('#project-archive');
        jQuery('html,body').animate(
            {
                scrollTop: loc.offset().top - 50
            },
            'slow'
        );
    }

    ajaxPostFunction(target, hash) {
        return new Promise((resolve, reject) => {
            var filter = jQuery('#filter');
            var data = jQuery(filter).serializeArray();
            var community = hash;
            var filterIndex = data.findIndex(
                (obj) => obj.name == 'community_filter'
            );

            if (community) {
                data[filterIndex].value = hash;
            }

            jQuery.ajax({
                url: filter.attr('action'),
                data: jQuery.param(data),
                type: filter.attr('method'),
                dataType: 'json',
                success: function (results) {
                    if (
                        jQuery('#project-archive-response').html() !=
                        results.html
                    ) {
                        if (results.count != 1) {
                            document.querySelector('[data-count]').innerHTML =
                                'Displaying ' + results.count + ' Results';
                        } else {
                            document.querySelector('[data-count]').innerHTML =
                                'Displaying ' + results.count + ' Result';
                        }

                        if (target) {
                            document.querySelector('.community-filter');
                            filter.value = data[filterIndex].value;
                        }

                        jQuery('#project-archive-response').fadeOut(
                            100,
                            function () {
                                jQuery('#project-archive-response').html(
                                    results.html
                                );
                                jQuery('#project-archive-response').fadeIn();
                                resolve(results);
                            }
                        );
                    }
                },

                error: function (error) {
                    reject(error);
                }
            });

            return false;
        });
    }
}

export { CommunityFilter };
