class PopUp {
    constructor() {
        this.registerEvent();
    }

    registerEvent() {
        document.addEventListener(
            'click',
            function (e) {
                if (
                    e.target &&
                    e.target.closest('[data-trigger="openPopUp"]')
                ) {
                    var trigger = e.target.closest('[data-index]');
                    var index = trigger.dataset.index;
                    var iframe = trigger.dataset.iframe
                        ? trigger.dataset.iframe
                        : null;

                    var openElement = document.querySelector(
                        '[data-popup].popup__open'
                    );
                    var element = document.querySelector(
                        `#popup[data-popup="${index}"]`
                    );
                    document.querySelector('body').classList.add('fixed');
                    if (openElement) {
                        element.classList.add('popup__open');
                        setTimeout(() => {
                            openElement.classList.remove('popup__open');
                        }, 500);
                    } else {
                        element.classList.add('popup__open');
                    }

                    const departmentString = trigger.dataset.department;
                    if (departmentString) {
                        const department = departmentString.split('|')[0];
                        jQuery('.department-list').each(function () {
                            if (
                                jQuery(this)
                                    .data('department')
                                    .includes(department)
                            ) {
                                jQuery(this).show();
                            } else {
                                jQuery(this).hide();
                            }
                        });
                    } else {
                        jQuery('.department-list').hide();
                    }
                }
            }.bind(this)
        );

        document.addEventListener(
            'click',
            function (e) {
                if (
                    e.target &&
                    e.target.closest('[data-trigger="closePopUp"]')
                ) {
                    var trigger = e.target.closest('[data-popup]');
                    document.querySelector('body').classList.remove('fixed');
                    trigger.classList.remove('popup__open');
                }
            }.bind(this)
        );
    }
}

export { PopUp };
