import { Slick } from '../slick';
import { Accordion } from '../accordion';
import { ProjectForm } from '../project_form';

class Contact {
    constructor() {
        this.form = document.querySelector('#contact-form');
        this.recaptcha = this.form.querySelector('#recaptcha');
        this.init();
    }

    init() {
        new Slick("[data-slider='project-archive']", {
            mobileFirst: true,
            adaptiveHeight: true,
            variableWidth: true,
            prevArrow: false,
            nextArrow: false,
            infinite: true,
            dots: true,
            centerMode: true,
            centerPadding: '0 60px',
            slidesToShow: 1,
            slidesToScroll: 1,
            responsive: [
                {
                    breakpoint: 900,
                    settings: 'unslick'
                }
            ]
        });

        console.log(this.form);
        console.log(this.recaptcha);
        if (this.recaptcha) {
            new ProjectForm(this.recaptcha.value, 'contact');
        }

        new Accordion(document.querySelector('#contact-offices'));

        AOS.init({
            offset: 50,
            disable: 'mobile'
        });
    }
}

export { Contact };
