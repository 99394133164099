import { Slick } from './slick';

class Timeline {
    constructor() {
        this.timelineSlides =
            document.querySelectorAll('.timeline__slide').length - 1;
        this.init();
    }

    init() {
        this.slideDetect(this.timelineSlides);

        new Slick("[data-slider='timeline']", {
            slidesToShow: 5,
            slidesToScroll: 1,
            infinite: false,
            dots: false,
            prevArrow: document.querySelector('.prev_arrow'),
            nextArrow: document.querySelector('.next_arrow'),
            responsive: [
                {
                    breakpoint: 1300,
                    settings: {
                        slidesToShow: 4
                    }
                },
                {
                    breakpoint: 800,
                    settings: {
                        slidesToShow: 2
                    }
                }
            ]
        });

        jQuery('[data-slider="images"]').each(function () {
            var slider = jQuery(this);

            slider.slick({
                slidesToShow: 1,
                slidesToScroll: 1,
                infinite: true,
                dots: true,
                prevArrow: slider
                    .parents()
                    .find('.timeline-detail__arrow--left'),
                nextArrow: slider
                    .parent()
                    .find('.timeline-detail__arrow--right')
            });

            slider.slickLightbox({
                src: 'src',
                itemSelector: '.timeline-detail--images img',
                slick: {
                    dots: true
                },
                appendDots: '<span>dot</span>'
            });
        });

        jQuery('.timeline-detail--item').hide();
        jQuery('.icon-timeline').on('click', function () {
            jQuery('h6').removeClass('actived');
            jQuery(this).siblings('h6').addClass('actived');
            jQuery('.icon-timeline').removeClass('icon-timeline--actived');
            jQuery(this).addClass('icon-timeline--actived');
            jQuery('.timeline-detail--item').hide();
            jQuery(
                '.timeline-detail--item[data-id="' +
                    jQuery(this).attr('data-index') +
                    '"]'
            ).show();
            jQuery('[data-slider="images"]').slick('refresh');
        });

        jQuery('.timeline-detail__more').on('click', function () {
            jQuery(this).parent('.timeline-detail__text--short').hide();
            jQuery(this).parent('.timeline-detail__text--short').next().show();
        });
        jQuery('.timeline-detail__less').on('click', function () {
            jQuery(this).parent('.timeline-detail__text--long').hide();
            jQuery(this).parent('.timeline-detail__text--long').prev().show();
        });
    }

    slideDetect(slides) {
        jQuery('[data-slider="timeline"]').on(
            'beforeChange',
            function (event, slick, currentSlide, nextSlide) {
                const container = document.querySelector(
                    '[data-timeline="container"]'
                );
                const prev = document.querySelector(
                    '.timeline__slider__arrows .prev_arrow'
                );
                const next = document.querySelector(
                    '.timeline__slider__arrows .next_arrow'
                );
                if (currentSlide == slides - 1) {
                    container.classList.remove('end');
                    container.classList.remove('start');
                    container.classList.add('progressing');
                }

                switch (nextSlide) {
                    case slides - 1:
                        container.classList.remove('start');
                        container.classList.add('end');
                        container.classList.remove('progressing');
                        next.classList.add('hidden');
                        break;

                    case slides - 2:
                        next.classList.remove('hidden');
                        break;

                    case 0:
                        container.classList.remove('end');
                        container.classList.add('start');
                        container.classList.remove('progressing');
                        prev.classList.add('hidden');
                        break;

                    case 1:
                        prev.classList.remove('hidden');
                        container.classList.remove('start');
                        container.classList.add('progressing');
                        break;

                    case currentSlide + 1: {
                        prev.classList.remove('hidden');
                        container.classList.remove('end');
                        container.classList.remove('start');
                        container.classList.add('progressing');
                        break;
                    }
                    case currentSlide - 1: {
                        container.classList.remove('end');
                        container.classList.remove('start');
                        container.classList.add('progressing');
                        break;
                    }
                }
            }
        );
    }
}

export { Timeline };
