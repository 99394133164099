import { ProjectForm } from '../project_form';

class SecondaryRegistration {
    constructor() {
        this.recaptcha = document.querySelector('#recaptcha');
        this.email = sessionStorage.getItem('email');
    }

    init() {
        if (!this.email) {
            window.location = '/';
        }

        if (this.recaptcha) {
            new ProjectForm(this.recaptcha.value, 'secondary');
            // Add Select2 to filter for styling
            jQuery('#answers_11736').select2({
                width: 'resolve',
                minimumResultsForSearch: Infinity
            });

            jQuery('#answers_11603').select2();
        }
        AOS.init({
            useClassNames: true,
            offset: 100,
            disable: 'mobile'
        });
    }
}

export { SecondaryRegistration };
